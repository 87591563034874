.navlink {
    font-family: 'Inter' !important;
    font-weight: 300 !important;
    color: #666666 !important;
    font-size: 15px;
}

a {
    color: black !important;
    text-decoration: none !important;
}

.navlink:hover {
    color: grey !important;
}
